const palette = {
  primary: {
    main: '#493AB1',
    shade_50: '#EEECF9',
    shade_100: '#DCD9F2',
    shade_200: '#B9B2E6',
    shade_300: '#968CD9',
    shade_400: '#7365CD',
    shade_500: '#493AB1',
    shade_600: '#40329A',
    shade_700: '#302673',
    shade_800: '#20194D',
    shade_900: '#100D26',
    shade_950: '#080613',
  },
  secondary: {
    main: '#272522',
    shade_50: '#F3F2F1',
    shade_100: '#E7E6E4',
    shade_200: '#CFCDC9',
    shade_300: '#B8B4AD',
    shade_400: '#A09A92',
    shade_500: '#272522',
    shade_600: '#6D675F',
    shade_700: '#524E47',
    shade_800: '#363430',
    shade_900: '#1B1A18',
    shade_950: '#0E0D0C',
  },
  coral_red: {
    main: '#DF635D',
    shade_50: '#FBEBEA',
    shade_100: '#F7D6D4',
    shade_200: '#EEADAA',
    shade_300: '#E6847F',
    shade_400: '#DD5B55',
    shade_500: '#DF635D',
    shade_600: '#AA2822',
    shade_700: '#801E19',
    shade_800: '#551411',
    shade_900: '#2B0A08',
    shade_950: '#150504',
  },
  fine_pine: {
    main: '#00B779',
    shade_50: '#E5FFF7',
    shade_100: '#CCFFEE',
    shade_200: '#99FFDD',
    shade_300: '#66FFCC',
    shade_400: '#33FFBB',
    shade_500: '#00B779',
    shade_600: '#00CC88',
    shade_700: '#009966',
    shade_800: '#006644',
    shade_900: '#003322',
    shade_950: '#001A11',
  },
  butterscotch_yellow: {
    main: '#FFAB00',
    shade_50: '#FFF7E5',
    shade_100: '#FFEECC',
    shade_200: '#FFDD99',
    shade_300: '#FFCC66',
    shade_400: '#FFBB33',
    shade_500: '#FFAB00',
    shade_600: '#CC8800',
    shade_700: '#996600',
    shade_800: '#664400',
    shade_900: '#332200',
    shade_950: '#1A1100',
  },
  persian_blue: {
    main: '#0052CC',
    shade_50: '#E5F0FF',
    shade_100: '#CCE0FF',
    shade_200: '#99C2FF',
    shade_300: '#66A3FF',
    shade_400: '#3385FF',
    shade_500: '#0052CC',
    shade_600: '#0052CC',
    shade_700: '#003D99',
    shade_800: '#002966',
    shade_900: '#001433',
    shade_950: '#000A1A',
  },
  basic: {
    main: '#ffffff',
    white: '#ffffff',
    black: '#000000',
    light_gray: '#8A8A8A',
    light_background: '#F6F9FC',
    danger_red: '#BF2600',
  },
};

export default palette;
