export const VOWELS = new Set(['a', 'e', 'i', 'o', 'u']);
/**
 * @returns pluralised `word` by appending an "s" when the `count > 0`
 */
export const pluralise = (word: string, count: number): string => {
  if (!word) return '';

  const len = word.length;
  const hasYAtEnd = word[len - 1].toLowerCase() === 'y';
  const hasVowelBeforeY = VOWELS.has(word[len - 2].toLowerCase());

  if (hasYAtEnd && !hasVowelBeforeY) {
    const splittedWord = word.slice(0, -1);
    return count > 1 ? `${splittedWord}ies` : word;
  }

  return count > 1 ? `${word}s` : word;
};

/**
 * Formats a number with commas as thousands separators.
 * @param {number} num - The number to format.
 * @returns {string} - The formatted number as a string.
 */
export const formatNumbeWithCommasString = (num: number) => {
  if (!num) return '';
  // Convert the number into readable format with commas using regex

  // https://stackoverflow.com/questions/51568821/works-in-chrome-but-breaks-in-safari-invalid-regular-expression-invalid-group
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
