// `unstable_createMuiStrictModeTheme` is used to suppress `findDOMNode` error messages
// reference: https://stackoverflow.com/a/64135466
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
import palette from './palette';

const theme = createMuiTheme({
  spacing: [
    0, // 0
    2, // 1
    4, // 2
    8, // 3
    12, // 4
    16, // 5
    24, // 6
    32, // 7
    40, // 8
    48, // 9
    64, // 10
    80, // 11
    96, // 12
    160, // 13
  ],
  palette: { ...palette },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif',
    ].join(','),
  },
});

export default theme;
