// @ts-nocheck
/* tslint:disable */
import React from 'react';
import {
  StylesProvider,
  createGenerateClassName,
} from '@material-ui/core/styles';

const generateClassName = createGenerateClassName({
  seed: 'exly-',
  productionPrefix: 'lib-',
});

const withGenerateClassName = (Component) => (props) => {
  return (
    <StylesProvider generateClassName={generateClassName}>
      <Component {...props} />
    </StylesProvider>
  );
};

export default withGenerateClassName;
